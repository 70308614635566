import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useData } from "../../provider/DataProvider";
import JsxParser from "react-jsx-parser";
import { v4 as uuidv4 } from "uuid";

const Service = () => {
  const title = useRef(null);
  const { data } = useData();

  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceFound, setServiceFound] = useState(
    data ? data.root.services.body.data[0] : null
  );

  useEffect(() => {
    document.title = "AGRI-NOVA | Service";

    let libele = searchParams.get("libele");
    if (!libele && data) {
      libele = data.root.services.body.data[0].libele;
    }

    if (data) {
      if (libele) {
        const serviceFound = data.root.services.body.data.find(
          (data) => data.libele === libele
        );
        if (serviceFound) {
          setServiceFound(serviceFound);
          if (title.current) {
            title.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
          return;
        }
      }
      window.location.url = "/service?libele=" + libele;
    }
  }, [data, searchParams, setSearchParams]);

  return (
    <>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <div
          className="w-100 d-flex flex-column justify-content-center align-items-center opact position-relative"
          style={{
            backgroundImage: "url(images/service/service_bg.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "90vh",
          }}
        ></div>
        <div className="w-100 text-center position-absolute d-flex flex-column align-items-center justify-content-center mx-auto px-3">
          <Link
            className="btn btn-success rounded-pill px-4 fs-5"
            style={{ marginTop: "150px" }}
            to="/contact"
            reloadDocument={true}
          >
            Demander un devis
          </Link>
        </div>
      </div>

      <div className="container d-flex flex-column">
        <div>
          <div ref={title}>{""}</div>
          <h3 className="text-center my-5">
            Nous offrons des solutions adaptées à toutes les étapes de votre
            projet afin de vous aider à tirer le meilleur profit de votre
            exploitation.
          </h3>
        </div>
        <div className="">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="d-flex flex-column mb-5">
                {data &&
                  serviceFound &&
                  data.root.services.body.data.map((data, key) => (
                    <Link
                      className={
                        "py-3 mt-1 btn border-0 border-bottom border-3 rounded-0 border-success text-center text-dark bg-light mb-3" +
                        (serviceFound.libele === data.libele ? " selected" : "")
                      }
                      key={uuidv4()}
                      to={"/service?libele=" + data.libele}
                      reloadDocument={true}
                    >
                      {data.libele}
                    </Link>
                  ))}
              </div>
            </div>

            {serviceFound && (
              <div className="col-lg-8 col-12 content">
                <JsxParser jsx={serviceFound.content} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
