import { useEffect, useRef, useState } from "react";
import { ContactComponent } from "../sub/SmallComponent";
import axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

const EMAIL_MIN_LENGTH = 5;
const EMAIL_MAX_LENGTH = 30;
const NAME_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 50;
const OCCUPATION_MIN_LENGTH = 3;
const OCCUPATION_MAX_LENGTH = 50;
const DETAILS_MIN_LENGTH = 10;
const DETAILS_MAX_LENGTH = 1e3;

const Contact = () => {
  const formRef = useRef();
  const captchaRef = useRef();

  const [token, setToken] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [tel, setTel] = useState();

  const captchOnChange = (value) => {
    setToken(value);
  };

  const validateRange = (field, str, min, max) => {
    let message = [];

    if (typeof str !== "string" && !(str instanceof String)) {
      message.push([`${field}`, `Le champ ${field} doit être un caractère.`]);
    }

    if (str.length < min || str.length > max) {
      message.push([
        `${field}`,
        `Le champ ${field} doit contenir au minimum ${min} caractères et au maximum ${max} caractères.`,
      ]);
    }

    return message;
  };

  const validateEmail = (field, str) => {
    let message = [];

    message.push(
      ...validateRange(field, str, EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH)
    );

    if (!/^[\w.-]+@[\w.-]+\.\w+$/.test(str)) {
      message.push([
        `${field}`,
        `Le champ ${field} doit être une adresse mail`,
      ]);
    }

    return message;
  };

  useEffect(() => {
    document.title = "AGRI-NOVA | Contact";
  }, []);

  const errorContains = (key) => {
    if (error && Array.isArray(error))
      for (let map of error) {
        if (map[0] === key) {
          return true;
        }
      }
    return false;
  };

  const errorValues = (key) => {
    let values = [];
    if (error && Array.isArray(error))
      for (let map of error) {
        if (map[0] === key) {
          values.push(map[1]);
        }
      }
    return values;
  };

  const validateField = (messageError, object) => {
    messageError.push(
      ...validateRange(
        "nom et prénom",
        object.name,
        NAME_MIN_LENGTH,
        NAME_MAX_LENGTH
      )
    );
    messageError.push(...validateEmail("email", object.email));
    messageError.push(
      ...validateRange(
        "profession",
        object.occupation,
        OCCUPATION_MIN_LENGTH,
        OCCUPATION_MAX_LENGTH
      )
    );
    messageError.push(
      ...validateRange(
        "message",
        object.details,
        DETAILS_MIN_LENGTH,
        DETAILS_MAX_LENGTH
      )
    );
  };

  const handleMessage = async (e) => {
    e.preventDefault();
    if (!token) {
      toast.error("Le captcha n'est pas vérifié !", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setLoading(true);

    let object = {};
    new FormData(formRef.current).forEach(
      (value, key) => (object[key] = value)
    );
    let messageError = [];
    setError();
    setSuccess(false);
    try {
      validateField(messageError, object);
      if (messageError.length > 0) {
        setError(messageError);
        setLoading(false);
        return;
      }

      object.token = token;

      let data = JSON.stringify(object);
      await axios.post("/.netlify/functions/send-email", data);
      formRef.current.reset();
      setTel();
      setSuccess(true);
      toast.success("Message envoyé avec succès !", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      captchaRef?.current.reset();
    } catch (error) {
      let message = "";
      if (error?.response?.data && Array.isArray(error.response.data)) {
        for (let tab of error.response.data) {
          message += tab[1] + "\n";
        }
      } else if (
        error?.response?.data &&
        typeof error.response.data === "string"
      ) {
        message = error.response.data;
      }
      toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
    setToken();
  };
  return (
    <>
      <div
        className="w-100 bg-secondary d-flex flex-column justify-content-center px-5 text-white position-relative opact mb-5 bg"
        style={{
          backgroundImage: "url(images/contact/contact_bg.jpeg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div>
          <span className="display-6">
            Une <strong>question,</strong> une <strong>soumission</strong>,
          </span>
        </div>
        <div className="display-3 fw-bold">Contactez-nous dès aujourd’hui</div>
      </div>

      <div className="container">
        {success === true ? (
          <div className="row">
            <div className="col-md-4 col-12 mt-4 mb-4" />
            <div className="col-md-8 col-12 mt-4 h1 alert alert-success">
              {" "}
              Message envoyé avec succès.
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-4 col-12 mt-4 mb-4">
            <div className="mt-4 mb-5">
              Remplissez le formulaire suivant pour toute question, demande de
              soumission ou d’information.
            </div>
            <div className="mt-5">
              <div className="h3">Pour nous contacter</div>
              <ContactComponent />
            </div>
          </div>
          <div className="col-md-8 col-12 mt-4">
            <form onSubmit={handleMessage} ref={formRef}>
              <div className="row">
                <label className="col-md-6 col-12 mt-4">
                  Nom & Prénom *
                  <br />
                  <input
                    type="string"
                    placeholder="Marc Kérékou"
                    name="name"
                    required
                    className={
                      "w-100 h-52 border-0 bg-light border-bottom border-3 " +
                      (errorContains("nom et prénom")
                        ? "border-danger"
                        : "border-success")
                    }
                  />
                  {error &&
                  error.length > 0 &&
                  errorContains("nom et prénom") ? (
                    <div className="alert alert-danger py-1">
                      {errorValues("nom et prénom").join(",")}
                    </div>
                  ) : null}
                </label>

                <label className="col-md-6 col-12 mt-4">
                  Email *
                  <br />
                  <input
                    type="string"
                    placeholder="example@email.com"
                    name="email"
                    required
                    className={
                      "w-100 h-52 border-0 bg-light border-bottom border-3 " +
                      (errorContains("email")
                        ? "border-danger"
                        : "border-success")
                    }
                  />
                  {error && error.length > 0 && errorContains("email") ? (
                    <div className="alert alert-danger py-1">
                      {errorValues("email").join(",")}
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="row">
                <label className="col-md-6 col-12 mt-4">
                  Téléphone *
                  <br />
                  <PhoneInput
                    placeholder="Numéro de téléphone"
                    value={tel}
                    onChange={setTel}
                    name="tel"
                    required
                    className="w-100 h-52 border-0 bg-light border-bottom border-3 border-success"
                  />
                </label>

                <label className="col-md-6 col-12 mt-4">
                  Profession *
                  <br />
                  <input
                    type="string"
                    placeholder="Ex : Agriculteur"
                    name="occupation"
                    required
                    className={
                      "w-100 h-52 border-0 bg-light border-bottom border-3 " +
                      (errorContains("profession")
                        ? "border-danger"
                        : "border-success")
                    }
                  />
                  {error && error.length > 0 && errorContains("profession") ? (
                    <div className="alert alert-danger py-1">
                      {errorValues("profession").join(",")}
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="row">
                <label className="col-md-6 col-12 mt-4">
                  Localisation *
                  <br />
                  <input
                    type="string"
                    placeholder="Abomey-Zou"
                    name="localisation"
                    required
                    className="w-100 h-52 border-0 bg-light border-bottom border-3 border-success"
                  />
                </label>

                <label className="col-md-6 col-12 mt-4">
                  Superficie (en hectares) *
                  <br />
                  <input
                    type="number"
                    placeholder="50"
                    name="area"
                    required
                    className="w-100 h-52 border-0 bg-light border-bottom border-3 border-success"
                  />
                </label>
              </div>
              <div className="row">
                <label className="col-12 mt-4">
                  Message *
                  <br />
                  <textarea
                    type="string"
                    placeholder="Entrer votre message"
                    name="details"
                    required
                    className={
                      "w-100 h-91 border-0 bg-light border-bottom border-3 " +
                      (errorContains("message")
                        ? "border-danger"
                        : "border-success")
                    }
                  />
                  {error && error.length > 0 && errorContains("message") ? (
                    <div className="alert alert-danger py-1">
                      {errorValues("message").join(",")}
                    </div>
                  ) : null}
                </label>
              </div>
              <div className="row">
                <div className="col-12">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_CAPTCHA_CLIENT}
                    onChange={captchOnChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-4">
                  {loading === true ? (
                    <ReactLoading
                      type={"spin"}
                      color={"black"}
                      height={40}
                      width={40}
                      className="mx-auto"
                    />
                  ) : (
                    <input
                      type="submit"
                      value="Envoyer"
                      // disabled={token ? false : true}
                      className="w-100 h-60 btn btn-success border-0 rounded-0"
                    />
                  )}
                </div>
              </div>

              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
