import { useEffect } from "react";
import { Link } from "react-router-dom";
import { datas } from "../../data/data";
import { useData } from "../../provider/DataProvider";
import { v4 as uuidv4 } from "uuid";

const Home = () => {
  const { data } = useData();

  useEffect(() => {
    document.title = "AGRI-NOVA | Accueil";
  }, []);

  return (
    <>
      <div className="w-100 d-flex justify-content-between align-items-center">
        <div
          className="w-100 d-flex flex-column position-relative"
          style={{ height: "90vh" }}
        >
          <video
            autoPlay
            muted
            loop
            poster="/video/video_bg.jpg"
            id="background-video"
          >
            <source src="video/home_bg.webm" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>

        <div className="w-100 text-center position-absolute d-flex flex-column align-items-center justify-content-center mx-auto px-3">
          <div
            className="display-2 text-white fw-bold my-5 px-3"
            style={{ fontFamily: "titi-bold" }}
          >
            {data?.root.home.presentation.p[0]}
          </div>

          <Link
            className="mt-5 btn btn-success rounded-pill px-4 fs-5 my-5"
            to="/contact"
            reloadDocument={true}
          >
            {data?.root.home.presentation.p[1]}
          </Link>
        </div>
      </div>

      <div
        className="w-100 bg-success p-3 d-flex justify-centent-center align-items-center"
        // style={{ minHeight: "10vh" }}
      >
        <div className="p w-100 text-center text-white text-uppercase">
          {data?.root.home.presentation.p[2]}
        </div>
      </div>
      <div className="d-flex flex-column container">
        <div className="row mt-5">
          <p className="text-center fs-5">
            Produire plus avec moins de ressources, c’est tout le principe de
            l’agriculture de précision. Elle repose sur l’utilisation des
            nouvelles technologies et l’intelligence artificielle afin de
            réduire la consommation d’eau, d’énergie et d’intrants tout en
            optimisant les rendements.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center my-5">
          <div className="display-6">Nos solutions</div>
        </div>
        <div className="row">
          {datas.map((data, key) => (
            <div className="col-sm-12 col-md-6" key={uuidv4()}>
              <table className="table">
                <thead>
                  <tr>
                    <th className="border-0 p-0">
                      <Link
                        to={"/service?libele=" + data.libele}
                        reloadDocument={true}
                      >
                        <div className="h5 text-center border border-2 py-2 btn-outline-success btn mb-0 rounded-0 w-100 border-success">
                          {data.libele}
                        </div>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-0 shadow-lg over-op-05">
                      <Link
                        to={"/service?libele=" + data.libele}
                        reloadDocument={true}
                      >
                        <div
                          style={{
                            backgroundImage: "url(" + data.bg + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            paddingBottom: "100%",
                          }}
                        ></div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Home;
