import { useEffect } from "react";
import { useData } from "../../provider/DataProvider";
import JsxParser from "react-jsx-parser";

const About = () => {
  const { data } = useData();

  useEffect(() => {
    document.title = "AGRI-NOVA | À propos";
  }, []);
  return (
    <>
      <div
        className="w-100 bg-secondary d-flex flex-column justify-content-center px-5 text-white position-relative opact mb-5 bg"
        style={{
          backgroundImage: "url(images/contact/contact_bg.jpeg)",
          backgroundSize: "cover",
        }}
      >
        <div className="display-6 fw-bold d-flex flex-column align-items-start">
          <div>
            <strong>{data?.root.about.presentation.p[0]}</strong>
          </div>
          <div>
            <strong>{data?.root.about.presentation.p[1]}</strong>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <p className="text-justify">{data?.root.about.body.p[0]}</p>
            <p className="text-justify">{data?.root.about.body.p[1]}</p>

            <p className="fs-4">{data?.root.about.body.p[2]}</p>

            {data?.root.about.body.p[3] ? (
              <section className="">
                <div className="float-start">
                  <img
                    src="/images/profil/picture.jpg"
                    alt="Maxime d'Almeida"
                    className="m-3 mt-2 ml-0"
                    style={{ width: "70px", height: "70px" }}
                  />
                </div>

                <div className="p text-justify">
                  {data && <JsxParser jsx={data?.root.about.body.p[3]} />}
                </div>
              </section>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
