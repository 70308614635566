import { Facebook, Twitter } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { ContactComponent } from "./sub/SmallComponent";

const Footer = () => {
  return (
    <div className="container-fluid bg-success text-white py-5">
      <div className="row">
        <div className="col-md-4 col-12 mb-4">
          <div className="h3">Découvrir plus</div>
          <ul className="ms-3 list-unstyled">
            <li className="py-2">
              <Link
                className="text-decoration-none text-white"
                to="/"
                reloadDocument={true}
              >
                Accueil
              </Link>
            </li>
            <li className="py-2">
              <Link
                className="text-decoration-none text-white"
                to="/service"
                reloadDocument={true}
              >
                Service
              </Link>
            </li>
            <li className="py-2">
              <Link
                className="text-decoration-none text-white"
                to="/propos"
                reloadDocument={true}
              >
                À propos de nous
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-12 mb-4">
          <div className="h3">Pour nous contacter</div>
          <ContactComponent color="text-white" />
        </div>
        <div className="col-md-4 col-12 mb-4">
          <div className="h3">Suivez-nous sur les réseaux sociaux</div>
          <div className="d-flex ms-3">
            <div>
              <Facebook size={30} />
            </div>
            <div className="ms-3">
              <Twitter size={30} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <span style={{ fontSize: "13px" }}>
          © {new Date().getFullYear()} AGRI-NOVA. Designed by DevFiles
        </span>
      </div>
    </div>
  );
};

export default Footer;
