import { Envelope, Phone, Whatsapp } from "react-bootstrap-icons";
import ReactWhatsapp from "react-whatsapp";

export const ContactComponent = ({ color }) => {
  return (
    <ul className="ms-3 list-unstyled">
      <li className="py-2">
        <Phone />
        <span> </span>
        <a
          className={"btn p-0" + (color ? " " + color : "")}
          href="tel:(+229) 97 37 92 41"
        >
          (+229) 97 37 92 41
        </a>
      </li>
      <li className="py-2">
        <Whatsapp />
        <span> </span>
        <span>
          <ReactWhatsapp
            number="+229 97 37 92 41"
            className={"btn p-0" + (color ? " " + color : "")}
          >
            (+229) 97 37 92 41
          </ReactWhatsapp>
        </span>
      </li>
      <li className="py-2">
        <Envelope />
        <span> </span>
        <a
          className={"btn p-0" + (color ? " " + color : "")}
          href="mailto:agrinovasc2023@gmail.com"
        >
          agrinovasc2023@gmail.com
        </a>
      </li>
    </ul>
  );
};
