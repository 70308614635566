import "./App.css";
import "./fonts/titillium/titilliumweb-black.ttf";
import "./fonts/titillium/titilliumweb-bold.ttf";
import "./fonts/titillium/titilliumweb-bolditalic.ttf";
import "./fonts/titillium/titilliumweb-extralight.ttf";
import "./fonts/titillium/titilliumweb-extralightitalic.ttf";
import "./fonts/titillium/titilliumweb-italic.ttf";
import "./fonts/titillium/titilliumweb-light.ttf";
import "./fonts/titillium/titilliumweb-lightitalic.ttf";
import "./fonts/titillium/titilliumweb-regular.ttf";
import "./fonts/titillium/titilliumweb-semibold.ttf";
import "./fonts/titillium/titilliumweb-semibolditalic.ttf";

import Header from "./component/Header";
import Home from "./component/page/Home";
import { Route, Routes } from "react-router-dom";
import Service from "./component/page/Service";
import About from "./component/page/About";
import Contact from "./component/page/Contact";
import Footer from "./component/Footer";
import NotFound from "./component/page/NotFound";

function App() {
  return (
    <>
      <Header />
      <div style={{ marginTop: "10vh", minHeight: "90vh" }} className="mb-5">
        <Routes forceRefresh={true}>
          <Route path="/" element={<Home />} />
          <Route path="/accueil" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/propos" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
