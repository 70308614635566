import axios from "axios";
import { createContext, useContext, useEffect, useState, useMemo } from "react";
import YAML from "yaml";
import PropTypes from "prop-types";

const DataContext = createContext();
const url =
  "https://dl.dropboxusercontent.com/scl/fi/qmkcg58lcdmd5bl47cn8j/agrinova.yml?rlkey=rvqy2rr2abk6oywemk6l7l8ng&dl=0";

export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        const parsedYaml = YAML.parse(response.data);
        setData(parsedYaml);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération du fichier YAML :",
          error
        );
      });
  }, []);

  return (
    <DataContext.Provider value={useMemo(() => ({ data }), [data])}>
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useData = () => {
  return useContext(DataContext);
};
