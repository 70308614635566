import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <nav className="navbar fixed-top bg-white" style={{ height: "10vh" }}>
      <div className="container-fluid h-100" style={{ flexWrap: "unset" }}>
        <Link className="navbar-brand text-center" to="/" reloadDocument={true}>
          <img
            src={"/images/logo/logo.png"}
            style={{ height: "8vh" }}
            alt="logo"
          />
        </Link>
        <div className="d-none d-lg-block">
          <ul className="navbar-nav flex-row">
            <li className="nav-item mx-3">
              <Link
                className={
                  "nav-link" +
                  (pathname === "/accueil" || pathname === "/"
                    ? " text-success"
                    : "")
                }
                aria-current="page"
                to="/accueil"
                reloadDocument={true}
              >
                Accueil
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link
                className={
                  "nav-link" + (pathname === "/service" ? " text-success" : "")
                }
                to="/service"
                reloadDocument={true}
              >
                Nos services
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link
                className={
                  "nav-link" + (pathname === "/propos" ? " text-success" : "")
                }
                to="/propos"
                reloadDocument={true}
              >
                À propos
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link
                className={
                  "nav-link btn btn-outline-success border border-success border-3 px-3 rounded-pill"
                  // (pathname === "/contact" ? " active" : "")
                }
                to="/contact"
                reloadDocument={true}
              >
                Contactez nous
              </Link>
            </li>
          </ul>
        </div>
        <button
          className="navbar-toggler d-lg-none col-md-block border-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            className="bi bi-list-nested"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          {/* <span className="navbar-toggler-icon"></span> */}
        </button>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              {" "}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body p-0 mt-5">
            <ul className="navbar-nav justify-content-end flex-grow-1 mt-5">
              <li
                className={
                  "nav-item py-2 ps-4 border-bottom border-4 border-success" +
                  (pathname === "/accueil" || pathname === "/"
                    ? " bg-success"
                    : "")
                }
              >
                <Link
                  className={
                    "nav-link" +
                    (pathname === "/accueil" || pathname === "/"
                      ? " active text-white"
                      : "")
                  }
                  aria-current="page"
                  // data-bs-dismiss="offcanvas"
                  to="/accueil"
                  reloadDocument={true}
                >
                  Accueil
                </Link>
              </li>
              <li
                className={
                  "nav-item py-2 ps-4 border-bottom border-4 border-success" +
                  (pathname === "/service" ? " bg-success" : "")
                }
              >
                <Link
                  className={
                    "nav-link" +
                    (pathname === "/service" ? " active text-white" : "")
                  }
                  to="/service"
                  reloadDocument={true}
                >
                  Nos services
                </Link>
              </li>
              <li
                className={
                  "nav-item py-2 ps-4 border-bottom border-4 border-success" +
                  (pathname === "/propos" ? " bg-success" : "")
                }
              >
                <Link
                  className={
                    "nav-link" +
                    (pathname === "/propos" ? " active text-white" : "")
                  }
                  to="/propos"
                  reloadDocument={true}
                >
                  À propos
                </Link>
              </li>
              <li className="nav-item h-56 text-center mt-4 mx-3">
                <Link
                  className={
                    "nav-link border btn btn-outline-success border-success border-3 px-3 rounded-pill"
                  }
                  to="/contact"
                  reloadDocument={true}
                >
                  Contactez nous
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
