import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="d-flex flex-column">
      <div className="container-fluid py-5">
        <p className="h3">404 Page non trouvée</p>

        <p>
          <Link to="/" reloadDocument={true}>
            Retour vers l'accueil
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
