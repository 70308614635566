export const datas = [
  {
    libele: "Cartographie aérienne",
    ancre: "CA",
    content: (
      <>
        <h2 className="text-start" id="CA">
          Connaitre et mieux gérer vos parcelles
        </h2>
        <p>
          Dans la planification d’un projet agricole, il est nécessaire de
          disposer d’une carte précise afin de délimiter l’exploitation,
          visualiser l’occupation parcellaire et concevoir un plan d’aménagement
          en fonction des caractéristiques topographiques de chaque parcelle.
        </p>
        <p>
          Équipés de GPS et de la technologie RTK «Real Time Kinematic», nos
          drones survolent le terrain à basse altitude. Ils collectent une
          multitude d’images géoréférencées afin de vous fournir plusieurs types
          de cartes (carte orthomosaïque, carte d’élévation et carte 3D) selon
          vos besoins et avec une précision au centimètre près.
        </p>

        <div className="mt-4">
          <img
            src="/images/service/service_bg_1.jpg"
            alt="agri-nova-service"
            className="img-fluid rounded"
          />
        </div>
      </>
    ),
    bg: "/images/service/service_bg_1.jpg",
  },
  {
    libele: "Diagnostic et suivi",
    ancre: "DS",
    content: (
      <>
        <h2 className="text-start" id="DS">
          Détection précoce de toutes maladies
        </h2>
        <p>
          L’identification rapide de toutes maladies et espèces envahissantes
          est indispensable pour une exploitation agricole rentable. Pour les
          grandes entreprises, ce diagnostic régulier est une tâche ardue.
          Heureusement, les drones agricoles permettent de diagnostiquer la
          santé des plantes et repérer toute espèces envahissantes sur plus de
          150 hectares en seulement quelques minutes de vol et quelques heures
          de traitement informatique. Nos drones de surveillance des plantes
          sont aussi équipés de capteurs multispectraux. Ces capteurs sont
          constitués de quatre bandes spectrales : le vert, le rouge, le
          red-edge et le proche infrarouge. Ils permettent de diagnostiquer
          l’état de santé et le stress des plantes en analysant la quantité de
          lumière qu’elles absorbent et réfléchissent. Plus concrètement, nos
          drones de surveillance peuvent détecter les maladies des plantes, et
          ce, jusqu’à deux semaines avant leur apparition dans le spectre visuel
          humain (à l’œil nu).
        </p>

        <div className="mt-4">
          <img
            src="/images/service/service_bg_2.jpg"
            alt="agri-nova-service"
            className="img-fluid rounded"
          />
        </div>
      </>
    ),
    bg: "/images/service/service_bg_2.jpg",
  },
  {
    libele: "Épandage par drone",
    ancre: "EPD",
    content: (
      <>
        <h2 className="text-start" id="EPD">
          Protégez vos cultures comme jamais auparavant
        </h2>
        <p>
          Les ravageurs seront toujours une problématique majeure en
          agriculture. On estime que plus de la moitié de la production agricole
          mondiale serait perdue sans une protection phytosanitaire adéquate.
          L’épandage par drone offre une protection maximale grâce à une
          application uniforme, automatisée et précise sur chaque parcelle. Le
          système de pulvérisation étant intelligent (émission de minuscules
          gouttelettes), il n’est plus nécessaire de fortement diluer le
          produit. Il est donc possible de traiter un hectare de culture avec
          seulement 5 litres d’eau, soit une économie d’eau de plus de 90 % par
          rapport aux méthodes traditionnelles.
        </p>
        <p>
          Nous sommes équipés de drones de capacité d’épandage de 40 litres.
          Avec une application de 10 L/hectares, ceux-ci sont capables de
          traiter plus de 25 hectares en une heure.
        </p>

        <div className="mt-4">
          <img
            src="/images/service/service_bg_3.jpg"
            alt="agri-nova-service"
            className="img-fluid rounded"
          />
        </div>
        <div className="mt-4">
          <img
            src="/images/service/service_bg_31.jpg"
            alt="agri-nova-service"
            className="img-fluid rounded"
          />
        </div>
      </>
    ),
    bg: "/images/service/service_bg_31.jpg",
  },
  {
    libele: "Solutions sur mesure",
    ancre: "SSM",
    content: (
      <>
        <h2 className="text-start" id="SSM">
          Concevoir ensemble des solutions adaptées a vos besoins
        </h2>
        <p>
          Avec l’évolution rapide de l’intelligence artificielle, de nouvelles
          applications du drone dans le monde agricole apparaissent chaque jour.
          Ailleurs dans le monde, les opérations suivantes sont déjà rendues
          possibles grâce aux drones :
        </p>
        <ul>
          <li>
            L’établissement de cartes de prescription pour des traitements ciblé
            permettant de réduire considérablement les coûts des intrants et les
            impacts environnementaux.
          </li>
          <li>
            Comptage automatique des plants pour évaluer le taux de germination
            des semences et estimer les rendements.
          </li>
          <li>Le semis du riz et des plants de couvertures.</li>
          <li>La surveillance des troupeaux.</li>
          <li>
            La constatation des dégâts agricoles pour les besoins des
            assurances.
          </li>
          <li>L’évaluation de la santé des forêts et couverts végétaux.</li>
          <li>
            Le suivi de la croissance et de la revitalisation des forêts, etc.
          </li>
        </ul>
        <p>
          Quels que soient vos besoins, contactez-nous et il nous fera plaisir
          d’échanger sur des avenues possibles pour vous aider à atteindre vos
          objectifs.
        </p>
        <div className="mt-4">
          <img
            src="/images/service/service_bg_41.jpg"
            alt="agri-nova-service"
            className="img-fluid rounded"
          />
        </div>
      </>
    ),
    bg: "/images/service/service_bg_41.jpg",
  },
];
